import React, {useState, useEffect} from "react"
import {Container,Form,Navbar,Nav} from 'react-bootstrap';
import { Link } from "gatsby"
import InputRange from 'react-input-range';
import NavLink from  "../../components/NavLink/Custom_Presentation"; 
import Header from  "../../components/Common/Header"; 
import SEO from "../../components/seo"

import {config} from '../../components/Common/constant';
import Compare_Cost from '../../components/modules/compare_cost';

import { GET_ARTICLE } from "../../queries/common_use_query";

import { useContainerContext } from "../../store/ContainerContext";

const CompareCost = (props) => {

  const context = useContainerContext();
  const { property_data } = context.state;

  const [ article_data, setArticleData ] = useState('');
  const { loading, error, data } = GET_ARTICLE('our-costs-compare-with-other-agents');

  useEffect(()=>{
      setArticleData(data && data.articles[0]);
  }, [data]);

    return <>
    
      <SEO title={article_data && article_data.Meta_Title} description={article_data && article_data.Meta_Description} />

      <div className="pre-wrapper">
        
        <Header/>
      
        {property_data && <Compare_Cost property_data={property_data} /> }

        <NavLink tab_name="Fees" prevLink={config.custom_comparables} nextLink={config.custom_contract_form} />

      </div>

    </>
}

export default CompareCost
